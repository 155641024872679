.scrollbar-small ::-webkit-scrollbar {
  width: 4px
}

::-webkit-scrollbar {
  width: 10px
}

/* Track */
::-webkit-scrollbar-track {
  background: #F2EEF9;
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #737EB9;
  border-radius: 5px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #626DA8
}